import logo from './logo.svg';
import './App.css';

function App() {
  return (
   <div>
    le site est en cours de construction
   </div>
  );
}

export default App;
